<template>
  <div>
    <head-bar title="Meldingen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" width="16" height="12" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="notifications">
      <form
        class="p-2 p-md-3"
        action=""
        method="POST"
        @submit.prevent="onSubmit"
        v-if="user"
        novalidate
        ref="form"
      >
        <Errors :errors="serverErrors" />

        <div class="form-group mb-3">
          <input
            type="checkbox"
            name="receive_email_notifications"
            id="receive_email_notifications"
            :checked="user.user.receive_email_notifications"
            value="1"
            class="custom-checkbox"
            @change="onNotificationMailChange"
          >
          <label for="receive_email_notifications">Ontvang meldingen via e-mail</label>
        </div>

        <div class="form-group" v-if="isPushSupported">
          <input
            type="checkbox"
            name="receive_push_notifications"
            id="receive_push_notifications"
            :checked="user.user.receive_push_notifications"
            value="1"
            class="custom-checkbox"
            @change="onNotificationPushChange"
          >
          <label for="receive_push_notifications">Ontvang pushmeldingen</label>
          <input type="hidden" name="one_signal_token" :value="onesignalToken">
        </div>
      </form>
    </app-layout>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import Errors from '../../components/Errors.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    HeadBarLink,
    HeadBar,
    AppLayout,
    Errors,
  },

  data() {
    return {
      serverErrors: [],
      onesignalToken: null,
      isPushSupported: false,
    };
  },

  created() {
    if (typeof window.OneSignal !== 'undefined') {
      window.OneSignal.push(() => {
        // Occurs when the user's subscription changes to a new value.
        window.OneSignal.on('subscriptionChange', this.onOneSignalSubscriptionChange.bind(this));

        window.OneSignal.getUserId((userId) => {
          if (this.user.user.receive_push_notifications) {
            this.onesignalToken = userId;
            this.onSubmit();
          }
        });
      });

      this.checkPushNotificationSupport();
    }
  },

  beforeUnmount() {
    if (typeof window.OneSignal !== 'undefined') {
      window.OneSignal.off('subscriptionChange', this.onOneSignalSubscriptionChange.bind(this));
    }
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onOneSignalSubscriptionChange(isSubscribed) {
      if (!isSubscribed) {
        this.onesignalToken = '';
        this.onSubmit();
        return;
      }

      window.OneSignal.getUserId((userId) => {
        this.onesignalToken = userId;
        this.onSubmit();
      });
    },

    onNotificationMailChange() {
      this.onSubmit();
    },

    onNotificationPushChange({ currentTarget }) {
      const target = currentTarget;

      // If the user unchecked, we remove the OneSignal token...
      if (!target.checked) {
        this.onesignalToken = '';
        this.onSubmit();
        return;
      }

      // If the OneSignal SDK is not loaded we uncheck the option
      // to let the user know that nothing happened.
      if (typeof window.OneSignal === 'undefined') {
        target.checked = false;
        return;
      }

      // Show the prompt to enable notifications...
      window.OneSignal.push(() => {
        // Try fetching the user in case they gave permission in the past.
        window.OneSignal.getUserId((userId) => {
          // If the user could not be found, ask for permission.
          if (!userId) {
            window.OneSignal.showNativePrompt();
            return;
          }

          // Store OneSignal userId.
          this.onesignalToken = userId;
          this.onSubmit();
        });
      });
    },

    onSubmit() {
      this.$nextTick(() => {
        const formData = new FormData(this.$refs.form);

        this.storeForm(formData);
      });
    },

    storeForm(formData) {
      this.serverErrors = [];

      this.$store.dispatch('putNotificationsForm', formData)
        .then(() => {
          this.toast.success('Notificatie instellingen opgeslagen', {
            timeout: 2500,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          if (!err.response) {
            this.serverErrors = ['Er ging iets mis. Probeer het later opnieuw.'];
            return;
          }

          this.serverErrors = retrieveErrorsFromResponse(err.response);
        });
    },

    checkPushNotificationSupport() {
      if (typeof window.OneSignal === 'undefined') {
        this.isPushSupported = false;
        return;
      }

      window.OneSignal.push(() => {
        this.isPushSupported = window.OneSignal.isPushNotificationsSupported();
      });
    },
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
  },
};
</script>

<style>
.app-layout.notifications {
  background-color: #fff;
  padding-top: 3.5rem;
}
</style>
